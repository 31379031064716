@import '@fortawesome/fontawesome-free/scss/regular';
@import '@fortawesome/fontawesome-free/scss/solid';
@import '@fortawesome/fontawesome-free/scss/fontawesome';

@import 'bootstrap-overrides';
@import 'bootstrap/scss/bootstrap';
@import '@ng-select/ng-select/themes/default.theme.css';

.invalid-feedback {
  font-size: 100%;
}

// Display an arrow when a column is sorted asc or desc
th[sortable],
span[sortable] {
  line-height: 1.6;
  cursor: pointer;
  &.desc::after,
  &.asc::after {
    content: '';
    display: block;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAmxJREFUeAHtmksrRVEUx72fH8CIGQNJkpGUUmakDEiZSJRIZsRQmCkTJRmZmJgQE0kpX0D5DJKJgff7v+ru2u3O3vvc67TOvsdatdrnnP1Y///v7HvvubdbUiIhBISAEBACQkAICAEhIAQ4CXSh2DnyDfmCPEG2Iv9F9MPlM/LHyAecdyMzHYNwR3fdNK/OH9HXl1UCozD24TCvILxizEDWIEzA0FcM8woCgRrJCoS5PIwrANQSMAJX1LEI9bqpQo4JYNFFKRSvIgsxHDVnqZgIkPnNBM0rIGtYk9YOOsqgbgepRCfdbmFtqhFkVEDVPjJp0+Z6e6hRHhqBKgg6ZDCvYBygVmUoEGoh5JTRvIJwhJo1aUOoh4CLPMyvxxi7EWOMgnCGsXXI1GIXlZUYX7ucU+kbR8NW8lh3O7cue0Pk32MKndfUxQFAwxdirk3fHappAnc0oqDPzDfGTBrCfHP04dM4oTV8cxr0SVzH9FF07xD3ib6xCDE+M+aUcVygtWzzbtGX2rPBrEUYfecfQkaFzYi6HjVnGBdtL7epqAlc1+jRdAap74RrnPc4BCijttY2tRcdN0g17w7HqZrXhdJTYAuS3hd8z+vKgK3V1zWPae0mZDMykadBn1hTQBLnZNwVrJpSe/NwEeDsEwCctEOsJTsgxLvCqUl2ACftEGvJDgjxrnBqkh3ASTvEWrIDQrwrnJpkB3DSDrGW7IAQ7wqnJtkBnLRztejXXVu4+mxz/nQ9jR1w5VB86ejLTFcnnDwhzV+F6T+CHZlx6THSjn76eyyBIOPHyDakhBAQAkJACAgBISAEhIAQYCLwC8JxpAmsEGt6AAAAAElFTkSuQmCC')
      no-repeat;
    background-size: 22px;
    width: 22px;
    height: 22px;
    float: left;
    margin-left: -22px;
  }
  &.desc::after {
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
  }
}
span[sortable] {
  &.desc::after,
  &.asc::after {
    margin-left: 0;
  }
}
// some more space for the sorting arrow
table.table-sm thead {
  th {
    &.desc,
    &.asc {
      padding-left: 24px;
    }
  }
}

// toastr styling
.toast-container.toast-top-center .ngx-toastr {
  width: 500px !important;
}
.toast-top-center {
  top: 1rem !important;
}

.mb-30-px {
  margin-bottom: 30px;
}

// needs to be in the global styles.scss because it styles divs within ngx-file-drop
ngx-file-drop .file-drop-drop-zone-gray {
  height: 100px;
  margin: auto;
  border: 2px dotted gray;
  border-radius: 30px;
}
ngx-file-drop .file-drop-content-gray {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  color: gray;
}

.cursor-pointer {
  cursor: pointer;
}

// invalid fields also get the bootstrap styling
input.ng-invalid {
  @extend .is-invalid;
}

ngb-modal-backdrop {
  z-index: 1050 !important;
}
